// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <img src="./images/logo_devvop.png" width={400} height="auto" alt='devvop'/>
        <p className='App-eslogan'>
          Global product industry
        </p>
        <p className="App-link">
          info@devvop.com
        </p>
      </header>
    </div>
  );
}

export default App;
